import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Banner from "./../../assests/banner.png";
import Navbar from "./../../components/Navbar";
import AudioPlayer from "./../../components/AudioPlayer";
import { useNavigate } from "react-router-dom";
import "./index.css";

export function SearchPage() {
  let navigate = useNavigate();
  const [categories, setCategories] = useState(null);
  const [nowPlaying, setNowPlaying] = useState(null);
  const [audioPLayList, setAudioPLayList] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedSong, setSelectedSong] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        await fetch("https://periyarfm.s3.amazonaws.com/public/data/audio-data.json", {
          method: "GET",
          mode: "cors",
        })
          .then((response) => response.json())
          .then(async (json) => {
            setCategories(json);
          })
          .catch((error) => {
            console.log(error);
          });
        const value = localStorage.getItem("nowPlaying");
        const audiosList = localStorage.getItem("audioList");
        if (audiosList !== null && value !== null) {
          setNowPlaying(Number(value));
          setAudioPLayList(JSON.parse(audiosList));
        } else {
          setNowPlaying(null);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const storeAudiList = async (
    selectedCategoryAudioList,
    index,
    description,
    category,
    title
  ) => {
    try {
      const jsonValue = JSON.stringify(selectedCategoryAudioList);
      localStorage.setItem("audioList", jsonValue);
      localStorage.setItem("nowPlaying", index.toString());
      // navigate(`/${category}/${title}/${description}`, {
      //   state: { description },
      // });
      for (var i = 0; i < title.length; i++) {
        if (title.slice(i, i + 1) === "?") {
          title = title.slice(0, i) + "-" + title.slice(i + 1);
        }
      }
      navigate(`/${category}/${title}/${description}`, {
        state: { description },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const searchedaudioList = categories?.filter((search) => {
    return search?.title?.includes(searchKeyword.toLowerCase());
  });
  return (
    <>
      <Navbar />
      <Container className="main_container" fixed>
        <img src={Banner} className="_bannerImg" />
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
            <div>
              <input
                type="text"
                placeholder="Search here..."
                className="searchInput"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
        {searchKeyword ? (
          <div style={{ marginTop: 20 }}>
            <Grid container justifyContent="center">
              {searchedaudioList.map((val, i) => {
                return (
                  <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                    <div
                      className="AudioListMain"
                      key={i}
                      onClick={() =>
                        storeAudiList(
                          searchedaudioList,
                          i,
                          val.audioFilePath.slice(14, val.audioFilePath.length),
                          val.category,
                          val.title
                        )
                      }
                    >
                      <div className="audioList">
                        <img
                          src={`https://periyarfm.s3.amazonaws.com${val.coverFilePath}`}
                          className="audioListImage"
                        />
                        <p className="audioTitle">{val.title}</p>
                      </div>
                      <p className="audioDes">{val.description}</p>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        ) : null}
      </Container>
      {/*
      {audioPLayList ? (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => navigate("/now-playing")}
        >
          <AudioPlayer audioList={audioPLayList} nowPlaying={nowPlaying} />
        </div>
      ) : null}
       */}
    </>
  );
}

export default SearchPage;
