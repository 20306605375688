import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  let navigate = useNavigate();
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h2 style={{ textAlign: "center" }}>யார் சொல்லியிருந்தாலும், <br/> எங்கு படித்திருந்தாலும், <br/> நானே சொன்னாலும் <br/> உனது புத்திக்கும் பொது அறிவுக்கும் பொருந்தாத எதையும் நம்பாதே! <br/> - தந்தை பெரியார்</h2>
      <br/> <br/> <br/> <br/> <br/>
      <p style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
        Let's Go to Home page !
      </p>
    </div>
  );
}

export default NotFound;
