import React, { useState, useEffect } from "react";
import Navbar from "./../../components/Navbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AudioPlayer from "./../../components/AudioPlayer";
import Slider from "react-slick";
import Card from "./../../components/cards";
import Banner from "./../../assests/banner.png";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import "./index.css";
// import Carousel from "react-material-ui-carousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
function Home(props) {
  let navigate = useNavigate();
  const [categories, setCategories] = useState(null);
  const [nowPlaying, setNowPlaying] = useState(null);
  const [audioPLayList, setAudioPLayList] = useState(null);
  const [data, setData] = useState(null);
  const [songs, setSongs] = useState(null);
  const [podCatsts, setPodCatsts] = useState(null);
  // useEffect(() => {
  //   const isBrowser = typeof window !== "undefined"
  //   const AOS = isBrowser ? require("aos") : undefined
  //   if (!!AOS) {
  //     AOS.init()
  //   }
  // }, [])
  useEffect(() => {
    (async () => {
      const songsCategory = [];
      const podCastCategory = [];
      try {
        const value = localStorage.getItem("nowPlaying");
        const audiosList = localStorage.getItem("audioList");
        if (audiosList !== null && value !== null) {
          setAudioPLayList(JSON.parse(audiosList));
          setNowPlaying(Number(value));
        } else {
          setNowPlaying(null);
          setAudioPLayList(null);
        }
        await fetch("https://periyarfm.s3.amazonaws.com/public/data/audio-data.json", {
          method: "GET",
          mode: "cors",
        })
          .then((response) => response.json())
          .then(async (json) => {
            setData(json);
            for (var i = 0; i < json.length; i++) {
              if (json[i].category === "இயக்கப் பாடல்கள்") {
                songsCategory.push(json[i]);
              }
              if (json[i].category === "செய்திகள் & தலையங்கம்") {
                podCastCategory.push(json[i]);
              }
            }
            const result = Object.values(
              json.reduce((acc, x) => {
                acc[x.category] = [...(acc[x.category] || []), x];
                return acc;
              }, {})
            );
            setCategories(result);
            setSongs(songsCategory);
            setPodCatsts(podCastCategory);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const storeAudiList = async (
    selectedCategoryAudioList,
    index,
    description,
    category,
    title
  ) => {
    try {
      const jsonValue = JSON.stringify(selectedCategoryAudioList);
      localStorage.setItem("audioList", jsonValue);
      localStorage.setItem("nowPlaying", index.toString());
      // navigate(`/${category}/${title}/${description}`, {
      //   state: { description },
      // });
      for (var i = 0; i < title.length; i++) {
        if (title.slice(i, i + 1) === "?") {
          title = title.slice(0, i) + "-" + title.slice(i + 1);
        }
      }
      navigate(`/${category}/${title}/${description}`, {
        state: { description },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1800 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1800, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      removeArrowOnDeviceType: "tablet",
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <Navbar />
      <Container className="main_container" fixed>
        <img src={Banner} className="_bannerImg" />
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={11} md={12} sm={12} spacing={4}>
            {/* <Slider {...settings} Type="func" Default={true}> */}
            {/* {categories &&
                categories.map((val, i) => {
                  return <Card key={i} data={val} />;
                })} */}
            {/* </Slider> */}
            {/* <Carousel
              autoPlay={false}
              indicators={true}
              swipe={true}
              cycleNavigation={true}
              animation="slide"
              duration={2000}
              // NextIcon={<img src="http://random.com/next" />}
              // PrevIcon={<img src="http://random.com/prev" />}
            >
              {categories &&
                categories.map((val, i) => {
                  return <Card key={i} data={val} />;
                })}
            </Carousel> */}
            {categories !== null && (
              <Carousel
                responsive={responsive}
                draggable={true}
                swipeable={true}
                // removeArrowOnDeviceType="mobile"
                showDots={false}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                // autoPlay={props.deviceType !== "mobile" ? true : false}
                containerClass="carousel-container"
                deviceType={props.deviceType}
                dotListClass="custom-dot-list-style"
                className="_carousel"
              >
                {categories &&
                  categories.map((val, i) => {
                    return <Card key={i} data={val} />;
                  })}
              </Carousel>
            )}
          </Grid>
        </Grid>
        <div style={{ marginTop: 30 }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
              <div className="specificCategory">
                <h2 className="specificCategoryHeading">Songs</h2>
                {songs &&
                  songs.map((val, i) => {
                    if (i <= 2) {
                      return (
                        <p
                          className="specificCategoryTitle"
                          key={i}
                          onClick={() =>
                            storeAudiList(
                              songs,
                              i,
                              val.audioFilePath.slice(
                                14,
                                val.audioFilePath.length
                              ),
                              val.category,
                              val.title
                            )
                          }
                        >
                          {i + 1}
                          {". "}
                          {val.title.length > 40
                            ? val.title.slice(0, 40)
                            : val.title}
                        </p>
                      );
                    }
                  })}

                <div className="cardFooter">
                  <Button
                    variant="contained"
                    size="small"
                    className="cardBtn"
                    onClick={() =>
                      navigate("/library-page", { state: { data: songs } })
                    }
                  >
                    Load More
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
              <div className="specificCategory">
                <h2 className="specificCategoryHeading">PodCast</h2>
                {podCatsts &&
                  podCatsts.map((val, i) => {
                    if (i <= 2) {
                      return (
                        <p
                          className="specificCategoryTitle"
                          key={i}
                          onClick={() =>
                            storeAudiList(
                              podCatsts,
                              i,
                              val.audioFilePath.slice(
                                14,
                                val.audioFilePath.length
                              ),
                              val.category,
                              val.title
                            )
                          }
                        >
                          {i + 1}
                          {". "}
                          {val.title.length > 40
                            ? val.title.slice(0, 40)
                            : val.title}
                        </p>
                      );
                    }
                  })}

                <div className="cardFooter">
                  <Button
                    variant="contained"
                    size="small"
                    className="cardBtn"
                    onClick={() =>
                      navigate("/library-page", { state: { data: podCatsts } })
                    }
                  >
                    Load More
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      {/*
      {audioPLayList ? (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => navigate("/now-playing")}
        >
          <AudioPlayer audioList={audioPLayList} nowPlaying={nowPlaying} />
        </div>
      ) : null}
       */}
    </div>
  );
}

export default Home;
