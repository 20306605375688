import React from "react";
import BasicRouter from "./config/Router";
import "./App.css";

function App() {
  return (
    <div>
      <BasicRouter />
    </div>
  );
}

export default App;
