import { useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Passers } from "prop-passer";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
  TwitterIcon,
  FacebookShareCount,
} from "react-share";
import "react-h5-audio-player/lib/styles.css";
import "./index.css";
import { red } from "@mui/material/colors";

export default function Player({ nowPlaying, audioList }) {
  let location = useLocation();
  let navigate = useNavigate();
  const [trackIndex, setTrackIndex] = useState(nowPlaying);

  const handleClickPrevious = () => {
    setTrackIndex((currentTrack) => {
      var updatedIndex =
        currentTrack === 0 ? audioList.length - 1 : currentTrack - 1;
      // navigate(
      //   `/${audioList[updatedIndex].category}/${
      //     audioList[updatedIndex].title
      //   }/${audioList[updatedIndex].audioFilePath.slice(
      //     14,
      //     audioList[updatedIndex].audioFilePath.length
      //   )}`
      // );
      for (var i = 0; i < audioList[updatedIndex].title.length; i++) {
        if (audioList[updatedIndex].title.slice(i, i + 1) === "?") {
          audioList[updatedIndex].title =
           audioList[updatedIndex].title.slice(0, i) +
            "-" +
            audioList[updatedIndex].title.slice(i + 1);
        }
      }
      navigate(
        `/${audioList[updatedIndex].category}/${
          audioList[updatedIndex].title
        }/${audioList[updatedIndex].audioFilePath.slice(
          14,
          audioList[updatedIndex].audioFilePath.length
        )}`
      );
      return currentTrack === 0 ? audioList.length - 1 : currentTrack - 1;
    });
  };
  console.log("audioFilePath");
  const handleClickNext = () => {
    setTrackIndex((currentTrack) => {
      var updatedIndex =
        currentTrack < audioList.length - 1 ? currentTrack + 1 : 0;
      // navigate(
      //   `/${audioList[updatedIndex].category}/${
      //     audioList[updatedIndex].title
      //   }/${audioList[updatedIndex].audioFilePath.slice(
      //     14,
      //     audioList[updatedIndex].audioFilePath.length
      //   )}`
      // );
      for (var i = 0; i < audioList[updatedIndex].title.length; i++) {
        if (audioList[updatedIndex].title.slice(i, i + 1) === "?") {
          audioList[updatedIndex].title =
            audioList[updatedIndex].title.slice(0, i) +
            "-" +
            audioList[updatedIndex].title.slice(i + 1);
        }
      }
      navigate(
        `/${audioList[updatedIndex].category}/${
          audioList[updatedIndex].title
        }/${audioList[updatedIndex].audioFilePath.slice(
          14,
          audioList[updatedIndex].audioFilePath.length
        )}`
      );
      return updatedIndex;
    });
  };
  // FIXME: Change the url to periyarfm.com
  // const url = "https://www.periyarbooks.uk";
  const url = "http://www.periyarfm.com";

  const ShareList = Passers({
    url,
    className: "network__share-button",
  })({
    className: "network cursor-pointer hover transition--default",
    title: `Share ${url}`,
  })("li");

  return (
    <div className="nowPlayingDiv" style={{
   backgroundColor:"rgb(10 10 10)",
   borderRadius:"15px"
    }}>
      <h2 className="songCategory">{audioList[trackIndex].category}</h2>
      <img
        src={`https://periyarfm.s3.amazonaws.com${audioList[trackIndex].coverFilePath}`}
        className="NowPLayingCoverImage"
      />
      <p className="songTitle">{audioList[trackIndex].title}</p>
     <p className="songDes">{audioList[trackIndex].description}</p>
      
  
     <AudioPlayer
        autoPlay={true}
        onEnded={handleClickNext}
        autoPlayAfterSrcChange={true}
        showSkipControls={true}
        showJumpControls={false}
        src={`https://periyarfm.s3.amazonaws.com${audioList[trackIndex].audioFilePath}`}
        onPlay={(e) => console.log("Now Playing")}
        onClickPrevious={handleClickPrevious}
        onClickNext={handleClickNext}
        showFilledVolume={false}
        //header={`Now playing: ${audioList[trackIndex].title}`}
        //footer="All music from: www.periyarfm.com"
      />

<div className="shareBtns" style={{
   paddingTop:"5px",
   paddingBottom:"5px"
    }}>
        {/* <ShareList> */}
        <WhatsappShareButton
          title={
            "Title: " +
            audioList[trackIndex].title +
            "\n" +
            "Category: " +
            audioList[trackIndex].category +
            "\n"
          }
          url={url + location.pathname}
        >
          <WhatsappIcon size={30} />
        </WhatsappShareButton>
        <FacebookShareButton
          title={
            "Title: " +
            audioList[trackIndex].title +
            "\n" +
            "Category: " +
            audioList[trackIndex].category +
            "\n"
          }
          url={url + location.pathname}
        >
          <FacebookIcon size={30} />
        </FacebookShareButton>
        <LinkedinShareButton
          title={
            "Title: " +
            audioList[trackIndex].title +
            "\n" +
            "Category: " +
            audioList[trackIndex].category +
            "\n"
          }
          url={url + location.pathname}
        >
          <LinkedinIcon size={30} />
        </LinkedinShareButton>
        <TwitterShareButton
          title={
            "Title: " +
            audioList[trackIndex].title +
            "\n" +
            "Category: " +
            audioList[trackIndex].category +
            "\n"
          }
          url={url + location.pathname}
        >
          <TwitterIcon size={30} />
        </TwitterShareButton>
        {/* </ShareList> */}
      </div>



    </div>



    
  );
}
