import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Navbar from "./../../components/Navbar";
import Player from "../../components/Player";
import { useParams, useLocation } from "react-router-dom";
import "./index.css";

export function PlayerPage() {
  let { audioDes } = useParams();
  console.log(audioDes);
  let location = useLocation();
  const [audioPLayList, setAudioPLayList] = useState(null);
  const [nowPlaying, setNowPlaying] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        if (location.state) {
          const value = localStorage.getItem("audioList");
          const indexNumber = localStorage.getItem("nowPlaying");
          const convertedNumber = Number(indexNumber);
          setNowPlaying(convertedNumber);
          setAudioPLayList(JSON.parse(value));
        } else {
          await fetch(
            "https://periyarfm.s3.amazonaws.com/public/data/audio-data.json",
            {
              method: "GET",
              mode: "cors",
            }
          )
            .then((response) => response.json())
            .then(async (json) => {
              var flag = false;
              for (var i = 0; i < json.length; i++) {
                if (
                  json[i]?.audioFilePath.slice(
                    14,
                    json[i]?.audioFilePath.length
                  ) == audioDes
                ) {
                  console.log(json[i]);
                  setNowPlaying(i);
                  break;
                }
              }
              setAudioPLayList(json);
              console.log("json",json);
              console.log("index",i);

            })
            .catch((error) => {
              console.log(error);
            });
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);
  return (
    <>
      <Navbar />
      <Container className="main_container" fixed>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
            {audioPLayList ? (
              <Player audioList={audioPLayList} nowPlaying={nowPlaying} />
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default PlayerPage;
