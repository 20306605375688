import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Banner from "./../../assests/banner.png";
import Navbar from "./../../components/Navbar";
import AudioPlayer from "./../../components/AudioPlayer";
import { useLocation, useNavigate } from "react-router-dom";

export function LibraryPage(props) {
  let navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  const [nowPlaying, setNowPlaying] = useState(null);
  const [audioPLayList, setAudioPLayList] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const value = localStorage.getItem("nowPlaying");
        const audiosList = localStorage.getItem("audioList");
        if (audiosList !== null && value !== null) {
          setAudioPLayList(JSON.parse(audiosList));
          setNowPlaying(Number(value));
        } else {
          setNowPlaying(null);
          setAudioPLayList(null);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const storeAudiList = async (
    selectedCategoryAudioList,
    index,
    description,
    category,
    title
  ) => {
    try {
      const jsonValue = JSON.stringify(selectedCategoryAudioList);
      localStorage.setItem("audioList", jsonValue);
      localStorage.setItem("nowPlaying", index.toString());
      // navigate(`/${category}/${title}/${description}`, {
      //   state: { description },
      // });
      for (var i = 0; i < title.length; i++) {
        if (title.slice(i, i + 1) === "?") {
          title = title.slice(0, i) + "-" + title.slice(i + 1);
        }
      }
          navigate(`/${category}/${title}/${description}`, {
        state: { description },
      });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Navbar />
      <Container className="main_container" fixed>
        <img src={Banner} className="_bannerImg" />
        <h2> {data[0]?.category}</h2>
        <div  className="library_list">
          <Grid container justifyContent="center">
            {data.map((val, i) => {
              return (
                <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                  <div
                    key={i}
                    onClick={() =>
                      storeAudiList(
                        data,
                        i,
                        val.audioFilePath.slice(14, val.audioFilePath.length),
                        val.category,
                        val.title
                      )
                    }
                    className="AudioListMain"
                  >
                    <div className="audioList">
                      <img
                        src={`https://periyarfm.s3.amazonaws.com${val.coverFilePath}`}
                        className="audioListImage"
                      />
                      <div>
                        <p className="audioTitle">{val.title}</p>
                      </div>
                    </div>
                    <p className="audioDes">{val.description}</p>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Container>
      {/*
      {audioPLayList ? (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => navigate("/now-playing")}
        >
          <AudioPlayer audioList={audioPLayList} nowPlaying={nowPlaying} />
        </div>
      ) : null}
       */}
    </>
  );
}

export default LibraryPage;
