import * as React from "react";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import "./index.css";
import shadows from "@mui/material/styles/shadows";

export default function Card({ data }) {
  let navigate = useNavigate();

  const storeAudiList = async (
    selectedCategoryAudioList,
    index,
    description,
    category,
    title
  ) => {
    try {
      const jsonValue = JSON.stringify(selectedCategoryAudioList);
      localStorage.setItem("audioList", jsonValue);
      localStorage.setItem("nowPlaying", index.toString());
      // navigate(`/${category}/${title}/${description}`, {
      //   state: { description },
      // });
      for (var i = 0; i < title.length; i++) {
        if (title[i] === "?") {
          title = title.slice(0, i) + "-" + title.slice(i + 1);
        }
      }
      navigate(`/${category}/${title}/${description}`, {
        state: { description },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      style={{
        margin: "5px",
        backgroundColor: "#000",
        paddingLeft: 15,
        borderRadius: 5,
        paddingRight: 15,
        paddingBottom: 10,
        height: 260,
        display: "flex",
        flexDirection: "column",
        paddingTop: 20,
        paddingBottom: 20,
        
      }}
    >
      <div style={{ flex: 1 }}>
        <Typography sx={{ color: "red" }} variant="h5" color="text.secondary">
          {data[0]?.category}
        </Typography>
        {data?.map((item, i) => {
          if (i <= 2) {
            return (
              <div
                className="fileItem"
                key={i}
                onClick={() =>
                  storeAudiList(
                    data,
                    i,
                    item.audioFilePath.slice(14, item.audioFilePath.length),
                    item.category,
                    item.title
                  )
                }
              >
                <img
                  src={`https://periyarfm.s3.amazonaws.com${item.coverFilePath}`}
                  className="coverImage"
                />
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    fontSize: 14,
                    color: "#fff",
                    marginLeft: 2,
                  }}
                  key={i}
                >
                  {item.title.length > 30
                    ? item.title.slice(0, 30)
                    : item.title}
                </Typography>
              </div>
            );
          }
        })}
      </div>
      <div className="cardFooter">
        <Button
          variant="contained"
          size="small"
          className="cardBtn"
          // onClick={() => alert("jjk")}
          onClick={() => navigate("/library-page", { state: { data: data } })}
        >
          Load More
        </Button>
      </div>
    </div>
  );
}
