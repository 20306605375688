import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Home,
  PlayerPage,
  SearchPage,
  LibraryPage,
  NotFound,
} from "./../pages";

export default class BasicRouter extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/:audio_category/:title/:audioDes"
            element={<PlayerPage />}
          />
          <Route exact path="/SearchPage" element={<SearchPage />} />
          <Route exact path="/library-page" element={<LibraryPage />} />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
